import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['paymentForm', 'cardElement', 'paymentError', 'tosAcceptance', 'tosAcceptanceError', 'guarantee', 'guaranteeError', 'debit', 'debitError', 'tosAcceptanceAt', 'guaranteeAt', 'debitAt'];

  stripe = Stripe(this.data.get('publishableKey'));
  paymentIntentUrl = this.data.get('paymentIntentUrl')
  guaranteeUrl = this.data.get('guaranteeUrl')
  debitUrl = this.data.get('debitUrl')
  expectedAmountCentsUrl = this.data.get('expectedAmountCentsUrl')

  connect = async() => {
    // Create an instance of the card Element
    const elements = this.stripe.elements();
    this.card = elements.create('card', { style: this.style });

    // Add an instance of the card Element into the cardElementTarget
    this.card.mount(this.cardElementTarget);

    const initialAmountCentsRequired = await(this.amountCentsRequired())

    this.guaranteeTarget.addEventListener('change', this.updateGuaranteeAt);
    this.debitTarget.addEventListener('change', this.updateDebitAt);
    this.tosAcceptanceTarget.addEventListener('change', this.updateTosAcceptanceAt);
    this.paymentFormTarget.addEventListener('submit', (event) => {
      this.handleSubmit(initialAmountCentsRequired)
    });
  }

  disconnect() {
    this.paymentFormTarget.removeEventListener('submit', this.handleSubmit);
    this.tosAcceptanceTarget.removeEventListener('change', this.updateTosAcceptanceAt);
    this.guaranteeTarget.removeEventListener('change', this.updateGuaranteeAt);
    this.debitTarget.removeEventListener('change', this.updateDebitAt);
  }

  disableButtonWithCharging() {
    this.paymentFormTarget.button.innerHTML = "<i class='fas fa-spinner fa-spin' style='margin-right: 10px;'></i>Chargement";
    this.paymentFormTarget.button.disabled = true;
  }

  disableButton() {
    this.paymentFormTarget.button.innerHTML = "Payer";
    this.paymentFormTarget.button.disabled = true;
  }

  enableButton() {
    this.paymentFormTarget.button.innerHTML = "Payer";
    this.paymentFormTarget.button.disabled = false;
  }

  isGuaranteeRequired = async() => {
    const response = await fetch(this.guaranteeUrl);
    if (response.redirected) {
      return "user not authorized"
    } else {
      const data = await response.json();
      return data.guaranteeRequired
    }
  }

  isDebitRequired = async() => {
    const response = await fetch(this.debitUrl);
    if (response.redirected) {
      return "user not authorized"
    } else {
      const data = await response.json();
      return data.debitRequired
    }
  }

  amountCentsRequired = async() => {
    const response = await fetch(this.expectedAmountCentsUrl);
    if (response.redirected) {
      return "user not authorized"
    } else {
      const data = await response.json();
      return data.amountCents
    }
  }

  updateTosAcceptanceAt = async (event) => {
    if (this.tosAcceptanceTarget.checked) {
      this.tosAcceptanceAtTarget.value = new Date().toString();
    } else {
      this.tosAcceptanceAtTarget.value = "";
    }
  }

  updateGuaranteeAt = async (event) => {
    if (this.guaranteeTarget.checked) {
      this.guaranteeAtTarget.value = new Date().toString();
    } else {
      this.guaranteeAtTarget.value = "";
    }
  }

  updateDebitAt = async (event) => {
    if (this.debitTarget.checked) {
      this.debitAtTarget.value = new Date().toString();
    } else {
      this.debitAtTarget.value = "";
    }
  }

  checkAmountCents = async (initialAmountCentsRequired) => {
    const actualAmountCentsRequired = await this.amountCentsRequired()
    const response = await fetch(this.expectedAmountCentsUrl);
    if (response.redirected) {
      return "user not authorized"
    } else {
      return actualAmountCentsRequired === initialAmountCentsRequired
    }
  }

  reloadPage = async(message) => {
    $(".modal#payment-modal").modal('hide')
    document.querySelector(".modal#payment-modal").remove()
    const response = await fetch(`${location.href.endsWith("/") && location.href.slice(0,-1) || location.href}.json?message=${message}`);
    const data = await response.json();
    document.getElementById('proceed_content').outerHTML = data.partials.proceedContent
    document.getElementById('flash').innerHTML = data.partials.flashes
    $("html,body").scrollTop(0)
  }

  handleSubmit = async (initialAmountCentsRequired) => {
    event.stopPropagation();
    event.preventDefault();

    this.disableButtonWithCharging();

    this.paymentErrorTarget.textContent = ""

    const isAmountCentsCorrect = await this.checkAmountCents(initialAmountCentsRequired)
    if (isAmountCentsCorrect === "user not authorized") {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    } else if (isAmountCentsCorrect) {
      document.getElementById('flash').innerHTML = ""
    } else {
      await this.reloadPage("Votre demande a été modifiée, la page a été réactualisée.")
      return false
    }

    let isTosAcceptanceChecked = false
    if (this.hasTosAcceptanceTarget) {
      isTosAcceptanceChecked = this.tosAcceptanceTarget.checked
      if (isTosAcceptanceChecked) {
        this.tosAcceptanceErrorTarget.textContent = "";
      } else {
        this.tosAcceptanceErrorTarget.textContent = "Vous devez valider les conditions générales d'utilisation et de vente et les conditions générales d'utilisation Stripe";
        this.enableButton();
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    const isGuaranteeRequired = await this.isGuaranteeRequired()
    let isGuaranteeChecked = false
    if (this.hasGuaranteeTarget) {
      isGuaranteeChecked = this.guaranteeTarget.checked
      if (isGuaranteeRequired === "user not authorized") {
        await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      } else if (isGuaranteeRequired) {
        if (isGuaranteeChecked) {
          this.guaranteeErrorTarget.textContent = "";
        } else {
          this.guaranteeErrorTarget.textContent = "Vous devez accepter la demande d'autorisation de prélèvement pour la caution du lieu qui sera effectuée deux jours avant la réservation.";
          this.enableButton();
        }
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    const isDebitRequired = await this.isDebitRequired()
    let isDebitChecked = false
    if (this.hasDebitTarget) {
      isDebitChecked = this.debitTarget.checked
      if (isDebitRequired === "user not authorized") {
        await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
        return false
      } else if (isDebitRequired) {
        if (isDebitChecked) {
          this.debitErrorTarget.textContent = "";
        } else {
          this.debitErrorTarget.textContent = "Vous devez accepter la demande d'autorisation de débit.";
          this.enableButton();
        }
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    if (isAmountCentsCorrect && isTosAcceptanceChecked && ((isGuaranteeRequired && isGuaranteeChecked) || (!isGuaranteeRequired)) && ((isDebitRequired && isDebitChecked) || (!isDebitRequired))) {
      const response = await fetch(this.paymentIntentUrl);
      const data = await response.json();
      if (response.ok === true) {
        if (data.amountCents === initialAmountCentsRequired) {
          const result = await this.stripe.confirmCardPayment(data.clientSecret, {
            payment_method: { card: this.card }
          })

          if (result.error) {
            // Inform the customer that there was an error
            if (result.error.message) {
              this.paymentErrorTarget.textContent = result.error.message;
              this.enableButton()
            } else {
              this.paymentErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr">concierge@cookoon.fr</a></span>`
              this.disableButton();
            }
            this.paymentErrorTarget.scrollIntoView()
          } else {
            $('.modal').modal('hide');
            this.paymentFormTarget.submit();
          }
        } else {
          this.paymentErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr"><a>concierge@cookoon.fr</a></span>`
          this.disableButton();
        }
      } else {
        this.paymentErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr"><a>concierge@cookoon.fr</a></span>`
        this.disableButton();
      }
    }
  };
};

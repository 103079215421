import { Controller } from 'stimulus';
import { French } from "flatpickr/dist/l10n/fr.js"
import { updateTypeNamesForDate } from '../../javascript/components/update_type_names_for_date';

export default class extends Controller {
  static targets = ['select2', 'cookoonButlerState', 'addressContainer', 'address', 'peopleCount', 'typeName', 'date', 'submitButton']

  newReservationUrl = this.data.get('newReservationUrl')
  unavailableDatesForChefUrl = this.data.get('unavailableDatesForChefUrl')
  isChefBookable = this.unavailableDatesForChefUrl != ""
  menusForChefUrl = this.data.get('menusForChefUrl')
  typeNames = this.hasTypeNameTarget && this.typeNameTargets.map(typeName => typeName.value)
  category = this.data.get('category')

  connect() {
    this.resizeCookoonButlerStateArea()
    this.resizeChefDescriptionArea()

    this.hasSelect2Target && this.initSelect2()
    this.hasDateTarget && this.initFlatpickr(JSON.parse(this.dateTarget.dataset.datesUnavailable), "")

    $(this.cookoonButlerStateTargets).on('change', event => {
      $(['#cookoon_butler_state .card.bg-none.border-primary', event.currentTarget.closest('.card')]).toggleClass(['bg-none', 'border-primary', 'bg-gray', 'border-gray'])
      if ($(event.currentTarget).val() === 'none') {
        $(this.addressContainerTarget).hasClass('invisible') && $(this.addressContainerTarget).toggleClass(['invisible', 'mb-0', 'visible'])
      } else {
        $(this.addressContainerTarget).hasClass('visible') && $(this.addressContainerTarget).toggleClass(['invisible', 'mb-0', 'visible'])
        document.getElementById('type_name').scrollIntoView({ behavior: 'smooth'})
      }
      this.authorizeOrDisableSubmitButton()
    })

    $(this.typeNameTargets).on('change', event => {
      $([event.currentTarget.closest('#type_name').querySelector("btn.btn-cookoon.d-flex"), event.currentTarget.closest('.card').parentNode.querySelector("btn.btn-cookoon")]).toggleClass(['d-none', 'd-flex'])
      $(['#type_name .card.bg-none.border-primary', event.currentTarget.closest('.card')]).toggleClass(['bg-none', 'border-primary', 'bg-gray', 'border-gray'])
      document.getElementById('people_count').scrollIntoView({ behavior: 'smooth'})
      this.updateMenus()
      this.refreshFlatpickr(event.currentTarget.value, this.dateTarget.value)
      this.authorizeOrDisableSubmitButton()
    })

    $(this.peopleCountTarget).on('select2:select', event => {
      event.params.data.id && document.getElementById('date').scrollIntoView({ behavior: 'smooth'})
      this.updateMenus()
      this.authorizeOrDisableSubmitButton()
    })

    $(this.dateTarget).on('change', event => {
      document.getElementById('menu_or_contact').scrollIntoView({ behavior: 'smooth'})
      updateTypeNamesForDate("", this.unavailableDatesForChefUrl, this.typeNames, event.target.value)
      this.authorizeOrDisableSubmitButton()
    })

    window.addEventListener("resize", () => {
      this.resizeCookoonButlerStateArea()
      this.resizeChefDescriptionArea()
    });
  }

  searchAddress() {
    const query = new URLSearchParams({
      cookoon_butler_state: $('input[name="reservation[cookoon_butler_state]"]:checked').val(),
      address: $(this.addressTarget).val(),
      params_to_check: "address"
    }).toString()
    const url = `${this.newReservationUrl}.json?${query}`

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          !$(this.addressTarget).hasClass('is-invalid') && $(this.addressTarget).toggleClass('is-invalid')
          $(this.addressTarget).nextAll('.invalid-feedback') && $(this.addressTarget).nextAll('.invalid-feedback').remove()
          this.addressTarget.insertAdjacentHTML('afterEnd', `<div class="invalid-feedback">${data.error}</div>`)
        } else {
          $(this.addressTarget).hasClass('is-invalid') && $(this.addressTarget).toggleClass('is-invalid')
          $(this.addressTarget).nextAll('.invalid-feedback') && $(this.addressTarget).nextAll('.invalid-feedback').remove()
          this.authorizeOrDisableSubmitButton()
          document.getElementById('type_name').scrollIntoView({ behavior: 'smooth'})
        }
      })
  }

  updateMenus() {
    if (this.isChefBookable) {
      const query = new URLSearchParams({
        type_name: $('input[name="reservation[type_name]"]:checked').val(),
        menu_id: $('input[name="reservation[menu_id]"]:checked').val() || '',
        people_count: $(this.peopleCountTarget).val(),
        date: $(this.dateTarget).val(),
        category: this.category,
      }).toString()
      const url = `${this.menusForChefUrl}?${query}`
      fetch(url)
        .then(response => response.json())
        .then(data => {
          document.querySelector('#menu_or_contact .menus').innerHTML = data.menus
        })
      }
  }

  authorizeOrDisableSubmitButton() {
    const isCookoonButlerStateSelected = $('input[name="reservation[cookoon_butler_state]"]:checked').length > 0
    const isAddressNecessary = $('input[name="reservation[cookoon_butler_state]"]:checked').val() === "none"
    const isAddressFilledAndValid = $(this.addressTarget).val() != "" && !$(this.addressTarget).hasClass('is-invalid')
    const isCookoonButlerStateSelectedAndValid = isAddressNecessary ? isAddressFilledAndValid : isCookoonButlerStateSelected
    const isTypeNameSelected = $('input[name="reservation[type_name]"]:checked').length > 0
    const isPeopleCountSelected = $(this.peopleCountTarget).find(':selected').length > 0
    const isDateSelected = $(this.dateTarget).val() != ""
    const isMenuSelected = !this.isChefBookable || $('input[name="reservation[menu_id]"]:checked').length > 0
    const isContactFilled = this.isChefBookable || ($('input[name="reservation[contact_attributes][first_name]"]').val() != "" && $('input[name="reservation[contact_attributes][last_name]"]').val() != "" && $('input[name="reservation[contact_attributes][email]"]').val() != "" && $('input[name="reservation[contact_attributes][phone_number]"]').val() != "")
    if (isCookoonButlerStateSelectedAndValid && isTypeNameSelected && isPeopleCountSelected && isDateSelected && isMenuSelected && isContactFilled) {
      $(this.submitButtonTarget).is(":disabled") && $(this.submitButtonTarget).prop("disabled", false)
    } else {
      !$(this.submitButtonTarget).is(":disabled") && $(this.submitButtonTarget).prop("disabled", true)
    }
  }

  removeMenuInvalidFeedback() {
    $("#menu_or_contact .invalid-feedback") && $("#menu_or_contact .invalid-feedback").remove()
  }

  toggleMenuCardsClasses(event) {
    $(['#menu_or_contact .card.bg-none.border-primary', event.currentTarget.closest('.card')]).toggleClass(['bg-none', 'border-primary', 'bg-gray', 'border-gray'])
  }

  disconnect() {
    this.hasSelect2Target && $(this.select2Targets).select2('destroy')
    this.hasDateTarget && flatpickr(this.dateTarget).destroy();
  }

  refreshFlatpickr = (typeName, selectedDate) => {
    if (this.isChefBookable) {
      const query = new URLSearchParams({
        type_name: typeName,
        start_date_available: this.dateTarget.dataset.startDateAvailable,
        end_date_available: this.dateTarget.dataset.endDateAvailable,
      }).toString()
      const url = `${this.unavailableDatesForChefUrl}?${query}`
      fetch(url)
        .then(response => response.json())
        .then((data) => {
          this.initFlatpickr(data["datesUnavailable"], selectedDate)
        })
    }
  }

  initSelect2() {
    $(this.select2Targets).select2({
      minimumResultsForSearch: -1,
    })
  }

  initFlatpickr = (datesUnavailable, selectedDate) => {
    flatpickr(this.dateTarget, {
      locale: French,
      altInput: true,
      altFormat: 'D j M',
      dateFormat: 'Y-m-d',
      disable: datesUnavailable,
      minDate: this.dateTarget.dataset.startDateAvailable,
      maxDate: this.dateTarget.dataset.endDateAvailable,
      disableMobile: "true",
      defaultDate: selectedDate,
    })
  }

  resizeCookoonButlerStateArea() {
    if (window.innerWidth >= 768 && window.matchMedia("(orientation: landscape)").matches) {
      document.getElementById("cookoon_butler_state").style.minHeight = `${window.innerHeight - document.querySelector(".navbar-cookoon-new-with-flash").offsetHeight - document.getElementById("breadcrumbs").offsetHeight}px`
    } else {
      document.getElementById("cookoon_butler_state").style.minHeight = ''
    }
  }

  resizeChefDescriptionArea() {
    if (document.querySelector('.first-letter-bigger').offsetHeight > document.querySelector('.chef-description').offsetHeight) {
      !$('.chef-description').hasClass('masked-overflow') && $('.chef-description').toggleClass(['align-items-center', 'masked-overflow'])
    } else {
      $('.chef-description').hasClass('masked-overflow') && $('.chef-description').toggleClass(['align-items-center', 'masked-overflow'])
    }
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import Select2 from "select2"
import flatpickr from 'vendor/flatpickr';
import Rails from '@rails/ujs';

export default class extends Controller {
  // static targets = ["cookoons", "chefs", "peopleCount", "typeNames", "startAt", "endAt"]
  static targets = ["startAt", "form", "selectOrDeselectAll", "address", "addressFormContainer", "addressFormAddress", "cookoon", "cookoonsList", "displayedAddress"]

  connect() {
    this.selectOrDeselectAllTargets.forEach(selectOrDeselect => {
      selectOrDeselect.addEventListener('change', (event, inputsToSelectOrDeselect) => {
        this.selectOrDeselect(event, event.currentTarget.closest('.list-of-inputs-with-checkboxes').querySelectorAll('.form-check-input.check_boxes'))
      });
    })

    // this.initSelect2("#search_cookoons")
    // this.updateSelect2("#search_cookoons", "cookoons")
    // this.initSelect2("#search_chefs")
    // this.updateSelect2("#search_chefs", "chefs")
    // this.initSelect2("#search_type_names")
    this.initSelect2PeopleCount()
    this.initFlatpickr(this.startAtTarget)
  }

  selectOrDeselect(event, inputsToSelectOrDeselect) {
    inputsToSelectOrDeselect.forEach(inputToSelectOrDeselect => {
      if (event.currentTarget.checked) {
        inputToSelectOrDeselect.checked = true
      } else {
        inputToSelectOrDeselect.checked = false
      }
    })
  }

  initSelect2PeopleCount() {
    $(".select2#search_people_count").select2()
    // $(".select2#search_people_count").on('select2:select', () => {
    //   this.submitForm()
    // });
  }

  initFlatpickr = (input) => {
    flatpickr(input, {
      mode: "range",
      altInput: true,
      altFormat: "D j F Y",
      dateFormat: 'Y-m-d',
      defaultDate: [JSON.parse(input.getAttribute("data-start-at")), JSON.parse(input.getAttribute("data-start-at"))],
      minDate: JSON.parse(input.getAttribute("data-start-at-available")),
      maxDate: JSON.parse(input.getAttribute("data-end-at-available")),
      // disableMobile: "true",
      // onClose: () => {
      //   this.submitForm()
      // }
    })
  }

  // initSelect2 = (id) => {
  //   $(`.select2${id}`).select2({multiple: true})
  // }

  // updateSelect2 = (id, objects) => {
  //   fetch(window.location.href, { headers: { accept: 'application/json' } })
  //     .then((response) => response.json())
  //     .then(data => {
  //       const objectsIds = data[objects].map(object => object["id"])
  //       $(`.select2${id}`).select2({multiple: true}).val(objectsIds).trigger("change");
  //     })
  // }

  hideOrRevealAddressForm(event) {
    $(this.addressFormContainerTarget).toggleClass([["visible"], ["invisible"]])
  }

  clearAddressIfCookoonPlace(event) {
    if (event.currentTarget.value === "cookoon") {
      this.addressTarget.value = ""
      this.addressFormAddressTarget.value = ""
      if (this.addressFormAddressTarget.classList.contains("is-invalid")) {
        this.addressFormAddressTarget.classList.remove("is-invalid")
      }
      this.displayedAddressTarget.innerText = ""
    }
  }

  uncheckCookoonsIfAtHome(event) {
    event.currentTarget.value === "at_home" && this.uncheckAll(this.cookoonTargets)
    $(this.cookoonsListTarget).toggleClass(["visible", "invisible"])
  }

  submitSearchForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  uncheck = (elements) => {
    elements.forEach(element => {
      if (element.checked === true) {
        element.checked = false
      }
    })
  }

  uncheckAll = (elements) => {
    elements.forEach(element => {
      this.uncheckOne(element)
    })
  }

  uncheckOne = (element) => {
    if (element.checked === true) {
      element.checked = false
    }
  }
}

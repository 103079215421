import { Controller } from 'stimulus';
import $ from 'jquery';
import flatpickr from 'vendor/flatpickr';
import Rails from '@rails/ujs';
import Select2 from "select2";
import { updateTypeNamesForDate } from '../../javascript/components/update_type_names_for_date';

export default class extends Controller {
  static targets = ["form", "peopleCount", "startAt", "typeName", "select2", "calendar", "startAtDayName", "startAtDay", "startAtMonth", "capacityError"]

  connect() {
    const startDateAvailable = this.startAtTarget.dataset.startAtAvailable
    const endDateAvailable = this.startAtTarget.dataset.endAtAvailable
    const unavailableDatesForCookoonUrl = this.formTarget.dataset.unavailableDatesForCookoonUrl
    const unavailableDatesForChefUrl = this.formTarget.dataset.unavailableDatesForChefUrl
    const datesUnavailable = JSON.parse(this.startAtTarget.dataset.datesUnavailable)
    const cookoonCapacity = parseInt(this.formTarget.dataset.cookoonCapacity)
    const cookoonCapacityStanding = parseInt(this.formTarget.dataset.cookoonCapacityStanding)
    const chefCapacity = parseInt(this.formTarget.dataset.chefCapacity)
    let peopleCount = this.peopleCountTarget.value
    let date = this.startAtTarget.value.split(" ")[0]
    const typeNames = [...this.typeNameTarget.options].map((option) => {
      return option.value
    }).filter(Boolean)

    this.initSelect2()

    this.initFlatpickr(this.calendarTarget, this.startAtTarget, this.startAtTarget.value, startDateAvailable, endDateAvailable, datesUnavailable, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)

    $(this.peopleCountTarget).on('select2:select', (event => {
      peopleCount = event.target.value
      this.updatePeopleCount(cookoonCapacity, cookoonCapacityStanding, chefCapacity)
      // this.updateTypeNamesForPeopleCount(peopleCount, cookoonCapacity, ["lunch", "diner"])
      // this.updateTypeNamesForPeopleCount(peopleCount, cookoonCapacityStanding, ["lunch_cocktail", "diner_cocktail"])
    }))

    $(this.calendarTarget).on('change', (event => {
      $(this.capacityErrorTarget).hasClass('visible') && $(this.capacityErrorTarget).toggleClass(['invisible', 'visible', 'alert', 'alert-danger', 'mt-3'])
      date = event.target.value
      updateTypeNamesForDate(unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date)
    }))

    $(this.typeNameTarget).on('change', (event => {
      this.updatePeopleCount(cookoonCapacity, cookoonCapacityStanding, chefCapacity)
      if (unavailableDatesForCookoonUrl || unavailableDatesForChefUrl) {
        this.unavailableDates(
          event.target.value,
          peopleCount,
          startDateAvailable,
          endDateAvailable,
          unavailableDatesForCookoonUrl,
          unavailableDatesForChefUrl
          ).then((newUnavailableDates) => {
            if (newUnavailableDates.includes(date)) {
              this.initFlatpickr(this.calendarTarget, this.startAtTarget, null, startDateAvailable, endDateAvailable, newUnavailableDates, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)
              this.startAtTarget.value = ""
              this.startAtDayNameTarget.innerText = ""
              this.startAtDayTarget.innerText = ""
              this.startAtMonthTarget.innerText = ""
            } else {
              this.initFlatpickr(this.calendarTarget, this.startAtTarget, this.startAtTarget.value, startDateAvailable, endDateAvailable, newUnavailableDates, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)
            }
          })
      }
    }))
  }

  // updateTypeNamesForPeopleCount = (peopleCount, capacity, typeNames) => {
  //   typeNames.forEach(typeName => {
  //     if (peopleCount > capacity) {
  //       $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', true).prop('disabled', true)
  //     } else {
  //       $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', false).prop('disabled', false)
  //     }
  //   })
  // }

  disconnect() {
    this.startAtTarget.flatpickr().destroy()
  }

  initSelect2() {
    this.select2Targets.forEach((select2) => {
      $(select2).select2({
        minimumResultsForSearch: -1
      })
    })
  }

  initFlatpickr = (input, startAtTarget, defaultDate, minDate, maxDate, datesUnavailable, clickOpens, startAtDayNameTarget, startAtDayTarget, startAtMonthTarget) => {
    flatpickr(input, {
      disableMobile: "true",
      altInput: true,
      altFormat: "D j F Y",
      dateFormat: 'Y-m-d',
      defaultDate: defaultDate,
      minDate: JSON.parse(minDate),
      maxDate: JSON.parse(maxDate),
      disable: datesUnavailable,
      clickOpens: clickOpens,
      altInputClass : "invisible w-0 p-0",
      wrap: true,
      onValueUpdate: function(selectedDates, dateStr, instance) {
        startAtTarget.value = dateStr
        startAtDayNameTarget.innerText = selectedDates[0].toLocaleDateString('fr-FR', { weekday: 'short' })
        startAtDayTarget.innerText = selectedDates[0].getDate()
        startAtMonthTarget.innerText = selectedDates[0].toLocaleDateString('fr-FR', { month: 'long' })
      },
    })
  }

  unavailableDates = (typeName, peopleCount, startDateAvailable, endDateAvailable, unavailableDatesForCookoonUrl, unavailableDatesForChefUrl) => {
    if (unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      return Promise.all(
          [
            this.unavailableDatesForSupplier(
              unavailableDatesForCookoonUrl,
              typeName,
              startDateAvailable,
              endDateAvailable
            ),
            this.unavailableDatesForSupplier(
              unavailableDatesForChefUrl,
              typeName,
              startDateAvailable,
              endDateAvailable
            ),
          ]
        ).then((values) => {
          return values[0].concat(values[1])
        })
    } else if (unavailableDatesForCookoonUrl && !unavailableDatesForChefUrl) {
      return this.unavailableDatesForSupplier(
          unavailableDatesForCookoonUrl,
          typeName,
          startDateAvailable,
          endDateAvailable
        ).then((values) => {
          return values
        })
    } else if (!unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      return this.unavailableDatesForSupplier(
          unavailableDatesForChefUrl,
          typeName,
          startDateAvailable,
          endDateAvailable
        ).then((values) => {
          return values
        })
    }
  }

  unavailableDatesForSupplier = (url, typeName, startDateAvailable, endDateAvailable) => {
    const urlToFetch = `${url}?type_name=${typeName}&start_date_available=${startDateAvailable}&end_date_available=${endDateAvailable}`
    return fetch(urlToFetch)
      .then(response => response.json())
      .then((data) => {
        return data.datesUnavailable
      }).catch(error => {
        // console.log(error)
      })
  }

  updatePeopleCount = (cookoonCapacity, cookoonCapacityStanding, chefCapacity) => {
    const peopleCountValue = parseInt(this.peopleCountTarget.value)
    const isCocktail = ["diner_cocktail", "lunch_cocktail"].includes(this.typeNameTarget.value)
    const maxCookoonCapacity =  isCocktail ? cookoonCapacityStanding : cookoonCapacity
    const maxCapacity = Math.min(maxCookoonCapacity, chefCapacity)
    let errorSentence = ""

    if (peopleCountValue > maxCapacity) {
      if (peopleCountValue > maxCookoonCapacity && peopleCountValue > chefCapacity) {
        errorSentence = `Le lieu accepte au plus ${maxCookoonCapacity} convives en format ${ isCocktail ? 'debout' : 'assis'}. Le chef peut cuisiner pour au plus ${chefCapacity} convives.`
      } else if (peopleCountValue > maxCookoonCapacity) {
        errorSentence = `Le lieu accepte au plus ${maxCookoonCapacity} convives en format ${ isCocktail ? 'debout' : 'assis'}.`
      } else if (peopleCountValue > chefCapacity) {
        errorSentence = `Le chef peut cuisiner pour au plus ${chefCapacity} convives.`
      }
      $(this.capacityErrorTarget).hasClass('invisible') && $(this.capacityErrorTarget).toggleClass(['invisible', 'visible', 'alert', 'alert-danger', 'mt-3'])
      $(this.peopleCountTarget).empty()
      const newOptions = Array.from(new Array(maxCapacity - 1), (x, i) => i + 2)
      newOptions.forEach(option => {
        $(this.peopleCountTarget).append(new Option(option, option, false, false))
      })
      $(this.peopleCountTarget).val(maxCapacity)

      $(this.peopleCountTarget).trigger('change')
    } else {
      $(this.capacityErrorTarget).hasClass('visible') && $(this.capacityErrorTarget).toggleClass(['invisible', 'visible', 'alert', 'alert-danger', 'mt-3'])
    }

    this.capacityErrorTarget.innerText = errorSentence
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['creditCardForm', 'cardElement', 'creditCardError', 'tosAcceptance', 'tosAcceptanceError', 'guarantee', 'guaranteeError', 'debit', 'debitError', 'tosAcceptanceAt', 'guaranteeAt', 'debitAt'];

  stripe = Stripe(this.data.get('publishableKey'));
  setupIntentUrl = this.data.get('setupIntentUrl')
  guaranteeUrl = this.data.get('guaranteeUrl')
  debitUrl = this.data.get('debitUrl')
  expectedAmountCentsUrl = this.data.get('expectedAmountCentsUrl')

  connect = async() => {
    // Create an instance of the card Element
    const elements = this.stripe.elements();
    this.card = elements.create('card', { style: this.style });

    // Add an instance of the card Element into the cardElementTarget
    this.card.mount(this.cardElementTarget);

    const initialAmountCentsRequired = await(this.amountCentsRequired())

    this.guaranteeTarget.addEventListener('change', this.updateGuaranteeAt);
    this.debitTarget.addEventListener('change', this.updateDebitAt);
    this.tosAcceptanceTarget.addEventListener('change', this.updateTosAcceptanceAt);
    this.creditCardFormTarget.addEventListener('submit', (event) => {
      this.handleSubmit(initialAmountCentsRequired, elements)
    });
  }

  disconnect() {
    this.creditCardFormTarget.removeEventListener('submit', this.handleSubmit);
    this.tosAcceptanceTarget.removeEventListener('change', this.updateTosAcceptanceAt);
    this.guaranteeTarget.removeEventListener('change', this.updateGuaranteeAt);
    this.debitTarget.removeEventListener('change', this.updateDebitAt);
  }

  disableButtonWithCharging() {
    this.creditCardFormTarget.button.innerHTML = "<i class='fas fa-spinner fa-spin' style='margin-right: 10px;'></i>Chargement";
    this.creditCardFormTarget.button.disabled = true;
  }

  disableButton() {
    this.creditCardFormTarget.button.innerHTML = "Payer";
    this.creditCardFormTarget.button.disabled = true;
  }

  enableButton() {
    this.creditCardFormTarget.button.innerHTML = "Payer";
    this.creditCardFormTarget.button.disabled = false;
  }

  isGuaranteeRequired = async() => {
    const response = await fetch(this.guaranteeUrl);
    const data = await response.json();
    return data.guaranteeRequired
  }

  isDebitRequired = async() => {
    const response = await fetch(this.debitUrl);
    const data = await response.json();
    return data.debitRequired
  }

  amountCentsRequired = async() => {
    const response = await fetch(this.expectedAmountCentsUrl);
    const data = await response.json();
    return data.amountCents
  }

  updateTosAcceptanceAt = async (event) => {
    if (this.tosAcceptanceTarget.checked) {
      this.tosAcceptanceAtTarget.value = new Date().toString();
    } else {
      this.tosAcceptanceAtTarget.value = "";
    }
  }

  updateGuaranteeAt = async (event) => {
    if (this.guaranteeTarget.checked) {
      this.guaranteeAtTarget.value = new Date().toString();
    } else {
      this.guaranteeAtTarget.value = "";
    }
  }

  updateDebitAt = async (event) => {
    if (this.debitTarget.checked) {
      this.debitAtTarget.value = new Date().toString();
    } else {
      this.debitAtTarget.value = "";
    }
  }

  checkAmountCents = async (initialAmountCentsRequired) => {
    const actualAmountCentsRequired = await this.amountCentsRequired()
    return actualAmountCentsRequired === initialAmountCentsRequired
  }

  reloadPage = async(message) => {
    $(".modal#credit-card-modal").modal('hide')
    document.querySelector(".modal#credit-card-modal").remove()
    const response = await fetch(`${location.href.endsWith("/") && location.href.slice(0,-1) || location.href}.json?message=${message}`);
    const data = await response.json();
    document.getElementById('proceed_content').outerHTML = data.partials.proceedContent
    document.getElementById('flash').innerHTML = data.partials.flashes
    $("html,body").scrollTop(0)
  }

  handleSubmit = async (initialAmountCentsRequired, elements) => {
    event.stopPropagation();
    event.preventDefault();

    this.disableButtonWithCharging();

    this.creditCardErrorTarget.textContent = ""

    const isAmountCentsCorrect = await this.checkAmountCents(initialAmountCentsRequired)
    if (isAmountCentsCorrect) {
      document.getElementById('flash').innerHTML = ""
    } else {
      await this.reloadPage("Votre demande a été modifiée, la page a été réactualisée.")
      return false
    }

    let isTosAcceptanceChecked = false
    if (this.hasTosAcceptanceTarget) {
      isTosAcceptanceChecked = this.tosAcceptanceTarget.checked
      if (isTosAcceptanceChecked) {
        this.tosAcceptanceErrorTarget.textContent = "";
      } else {
        this.tosAcceptanceErrorTarget.textContent = "Vous devez valider les conditions générales d'utilisation et de vente et les conditions générales d'utilisation Stripe";
        this.enableButton();
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    const isGuaranteeRequired = await this.isGuaranteeRequired()
    let isGuaranteeChecked = false
    if (this.hasGuaranteeTarget) {
      isGuaranteeChecked = this.guaranteeTarget.checked
      if (isGuaranteeRequired) {
        if (isGuaranteeChecked) {
          this.guaranteeErrorTarget.textContent = "";
        } else {
          this.guaranteeErrorTarget.textContent = "Vous devez accepter la demande d'autorisation de prélèvement pour la caution du lieu qui sera effectuée deux jours avant la réservation.";
          this.enableButton();
        }
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    const isDebitRequired = await this.isDebitRequired()
    let isDebitChecked = false
    if (this.hasDebitTarget) {
      isDebitChecked = this.debitTarget.checked
      if (isDebitRequired) {
        if (isDebitChecked) {
          this.debitErrorTarget.textContent = "";
        } else {
          this.debitErrorTarget.textContent = "Vous devez accepter la demande d'autorisation de débit.";
          this.enableButton();
        }
      }
    } else {
      await this.reloadPage("Une erreur est survenue, veuillez essayer à nouveau.")
      return false
    }

    if (isAmountCentsCorrect && isTosAcceptanceChecked && ((isGuaranteeRequired && isGuaranteeChecked) || (!isGuaranteeRequired)) && ((isDebitRequired && isDebitChecked) || (!isDebitRequired))) {
      const response = await fetch(this.setupIntentUrl);
      const data = await response.json();

      if (response.ok === true) {
        if (data.amountCents === initialAmountCentsRequired) {
          const result = await this.stripe.confirmCardSetup(data.clientSecret, {
            payment_method: {
              card: this.card,
            },
          })
          if (result.error) {
            // Inform the customer that there was an error
            if (result.error.message) {
              this.creditCardErrorTarget.textContent = result.error.message;
              this.enableButton()
            } else {
              this.creditCardErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr">concierge@cookoon.fr</a></span>`
              this.disableButton();
            }
            this.creditCardErrorTarget.scrollIntoView()
          } else {
            $('.modal').modal('hide');
            this.creditCardFormTarget.submit();
          }
        } else {
          this.creditCardErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr"><a>concierge@cookoon.fr</a></span>`
          this.disableButton();
        }
      } else {
        this.creditCardErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr"><a>concierge@cookoon.fr</a></span>`
        this.disableButton();
      }
    }
  };
}

// import { Controller } from 'stimulus';
// import Rails from 'rails-ujs';

// export default class extends Controller {
//   static targets = ['cardElement', 'cardError', 'form', 'tokenInput', 'cardholderInput', 'tosAcceptance', 'tosAcceptanceError'];

//   stripe = Stripe(this.data.get('publishableKey'));

//   style = {
//     base: {
//       fontSize: '16px',
//       color: '#2C2C2C',
//       iconColor: '#2C2C2C',
//       '::placeholder': {
//         color: '#c9c9c9'
//       }
//     }
//   };

//   connect() {
//     // Create an instance of the card Element
//     const elements = this.stripe.elements();
//     this.card = elements.create('card', { style: this.style });

//     // Add an instance of the card Element into the cardElementTarget
//     this.card.mount(this.cardElementTarget);

//     this.card.addEventListener('change', this.handleCardError);

//     // Create a token or display an error when the form is submitted.
//     this.formTarget.addEventListener('submit', this.handleSubmit);

//     // iOS keyboard fix
//     $('#credit-card-modal').on('hide.bs.modal', function () {
//       $('#invisible-input').focus();
//       $('#invisible-input').blur();
//     });
//   }

//   disconnect() {
//     this.card.removeEventListener('change', this.handleCardError);
//     this.formTarget.removeEventListener('submit', this.handleSubmit);
//     this.card = null;
//   }

//   disableButton() {
//     this.formTarget.button.innerHTML = "<i class='fas fa-spinner fa-spin' style='margin-right: 10px;'></i>Chargement";
//     this.formTarget.button.disabled = true;
//   }

//   enableButton() {
//     this.formTarget.button.innerHTML = "Ajouter une carte";
//     this.formTarget.button.disabled = false;
//   }

//   handleCardError = ({ error }) => {
//     if (error) {
//       this.cardErrorTarget.textContent = error.message;
//     } else {
//       this.cardErrorTarget.textContent = '';
//     }
//   };

//   handleSubmit = async (event) => {
//     event.stopPropagation();
//     event.preventDefault();

//     this.disableButton();

//     // Check if general conditions checked
//     if (this.tosAcceptanceTarget.checked) {
//       const response = await fetch(this.data.get("url"));
//       // console.log(response);

//       const data = await response.json();
//       // console.log(data.client_secret);

//       const token = await this.stripe.confirmCardSetup(data.client_secret, {
//         payment_method: {
//           card: this.card,
//           billing_details: {
//             name: this.cardholderInputTarget.value,
//           },
//           metadata: {
//             tos_acceptance: this.tosAcceptanceTarget.checked,
//           },
//         },
//       });

//       // console.log(token);
//       // console.log(token.error);
//       if (token.error) {
//         // Inform the customer that there was an error
//         this.cardErrorTarget.textContent = token.error.message;
//         this.enableButton();
//       } else {
//         // Send the token to your server
//         this.handleStripeToken(token);
//       }

//     } else {
//       this.tosAcceptanceErrorTarget.textContent = "Vous devez valider les conditions générales d'utilisation et les conditions générales d'utilisation Stripe";
//       this.enableButton();
//     }

//   };

//   handleStripeToken = token => {
//     // console.log(token);
//     // Insert the token ID into the form so it gets submitted to the server
//     this.tokenInputTarget.value = token.setupIntent.payment_method;

//     // Submit the form
//     this.formTarget.removeEventListener('submit', this.handleSubmit);
//     Rails.fire(this.formTarget, 'submit');
//     // To enable raise in credit_cards controller / create, comment the line upper et uncomment the one below
//     // this.formTarget.submit();
//   };
// };

import { Controller } from 'stimulus';
import { French } from "flatpickr/dist/l10n/fr.js"
import { updateTypeNamesForDate } from '../../javascript/components/update_type_names_for_date';

export default class extends Controller {
  static targets = ['select2', 'typeName', 'date', 'cookoonButlerState', 'address']

  unavailableDatesForChefUrl = this.data.get('unavailableDatesForChefUrl')
  typeNames = this.hasTypeNameTarget && [...this.typeNameTarget.options].map(option => option.value)

  connect() {
    this.hasSelect2Target && this.initSelect2()
    this.hasDateTarget && this.initFlatpickr(JSON.parse(this.dateTarget.dataset.datesUnavailable), "")

    this.hasCookoonButlerStateTarget && $(this.cookoonButlerStateTarget).on('select2:select', event => {
      $(this.addressTarget.closest(".form-group")).toggleClass(['invisible', 'mb-0', 'visible'])
      // if(this.addressTarget.classList.contains('is-invalid')) this.addressTarget.value = ''
      // $(this.addressTarget).toggleClass(['is-invalid', 'is-valid'])
    })

    this.hasTypeNameTarget && $(this.typeNameTarget).on('select2:select', event => {
      this.unavailableDatesForChefUrl && this.refreshFlatpickr(event.params.data.id, this.dateTarget.value)
    })

    this.hasDateTarget && $(this.dateTarget).on('change', event => {
      updateTypeNamesForDate("", this.unavailableDatesForChefUrl, this.typeNames, event.target.value)
    })
  }

  disconnect() {
    this.hasSelect2Target && $(this.select2Targets).select2('destroy')
    this.hasDateTarget && flatpickr(this.dateTarget).destroy();
  }

  refreshFlatpickr = (typeName, selectedDate) => {
    const startDateAvailable = this.dateTarget.dataset.startDateAvailable
    const endDateAvailable = this.dateTarget.dataset.endDateAvailable

    const url = `${this.unavailableDatesForChefUrl}?type_name=${typeName}&start_date_available=${startDateAvailable}&end_date_available=${endDateAvailable}`
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        this.initFlatpickr(data["datesUnavailable"], selectedDate)
      })
  }

  initSelect2() {
    $(this.select2Targets).select2({
      minimumResultsForSearch: -1,
    })
  }

  initFlatpickr = (datesUnavailable, selectedDate) => {
    flatpickr(this.dateTarget, {
      locale: French,
      altInput: true,
      altFormat: 'D j M',
      dateFormat: 'Y-m-d',
      disable: datesUnavailable,
      minDate: this.dateTarget.dataset.startDateAvailable,
      maxDate: this.dateTarget.dataset.endDateAvailable,
      disableMobile: "true",
      defaultDate: selectedDate,
    })
  }
}

import { Controller } from 'stimulus';
import $ from 'jquery';
import Select2 from "select2"

export default class extends Controller {
  static targets = ["select2"]

  connect() {
    const select2MinimumResultsForSearch = this.scope.element.dataset.select2MinimumResultsForSearch

    if (typeof(Number(select2MinimumResultsForSearch)) === "number") {
      $(this.select2Targets).select2({
        minimumResultsForSearch: select2MinimumResultsForSearch
      })
    } else {
      $(this.select2Targets).select2()
    }
  }
}

import { Controller } from 'stimulus';
import { French } from "flatpickr/dist/l10n/fr.js"
import Rails from '@rails/ujs';
import { updateTypeNamesForDate } from '../../javascript/components/update_type_names_for_date';

export default class extends Controller {
  static targets = ['select2', 'date', 'form', 'typeName', 'peopleCount', 'peopleCountError']

  unavailableDatesForChefUrl = this.data.get('unavailableDatesForChefUrl')
  unavailableDatesForCookoonUrl = this.data.get('unavailableDatesForCookoonUrl')
  cookoonCapacity = this.data.get('cookoonCapacity')
  cookoonCapacityStanding = this.data.get('cookoonCapacityStanding')
  chefCapacity = this.data.get('chefCapacity')
  startDateAvailable = this.dateTarget.dataset.startDateAvailable
  endDateAvailable = this.dateTarget.dataset.endDateAvailable
  typeNames = this.hasTypeNameTarget && [...this.typeNameTarget.options].map(option => option.value)

  connect() {
    this.initSelect2()
    this.initFlatpickr(JSON.parse(this.dateTarget.dataset.datesUnavailable), "")

    $(this.dateTarget).on('change', () => {
      updateTypeNamesForDate(this.unavailableDatesForCookoonUrl, this.unavailableDatesForChefUrl, this.typeNames, event.target.value)
      Rails.fire(this.formTarget, 'submit')
    })

    $(this.typeNameTarget).on('change', event => {
      this.setUnavailableDates(event.currentTarget.value).then((unavailableDates) => {
        this.initFlatpickr(unavailableDates, this.dateTarget.value)
      })
      this.updatePeopleCounts()
      Rails.fire(this.formTarget, 'submit')
    })

    $(this.peopleCountTarget).on('change', () => {
      Rails.fire(this.formTarget, 'submit')
    })
  }

  disconnect() {
    this.hasSelect2Target && $(this.select2Targets).select2('destroy')
    this.hasDateTarget && flatpickr(this.dateTarget).destroy();
  }

  initSelect2() {
    $(this.select2Targets).select2({
      minimumResultsForSearch: -1,
    })
  }

  initFlatpickr = (datesUnavailable, selectedDate) => {
    flatpickr(this.dateTarget, {
      locale: French,
      altInput: true,
      altFormat: 'D j M',
      dateFormat: 'Y-m-d',
      disable: datesUnavailable,
      minDate: this.startDateAvailable,
      maxDate: this.endDateAvailable,
      disableMobile: "true",
      defaultDate: selectedDate,
    })
  }

  setUnavailableDatesForSupplier = async (unavailableDatesForSupplierUrl, typeName) => {
    const urlToFetch = `${unavailableDatesForSupplierUrl}?type_name=${typeName}&start_date_available=${this.startDateAvailable}&end_date_available=${this.endDateAvailable}`
    return fetch(urlToFetch)
      .then(response => response.json())
      .then((data) => {
        return data.datesUnavailable
      }).catch(error => {
        // console.log(error)
      })
  }

  setUnavailableDates = async (typeName) => {
    return Promise.all(
      [
        this.unavailableDatesForCookoonUrl && this.setUnavailableDatesForSupplier(
          this.unavailableDatesForCookoonUrl, typeName
        ),
        this.unavailableDatesForChefUrl && this.setUnavailableDatesForSupplier(
          this.unavailableDatesForChefUrl, typeName
        ),
      ]
    ).then((values) => {
      return values.flat().filter(Boolean)
    })
  }

  updatePeopleCounts = () => {
    let peopleCount = parseInt($(this.peopleCountTarget).val())
    const isCocktail = ["lunch_cocktail", "diner_cocktail"].includes(this.typeNameTarget.value)
    const cookoonCapacity = isCocktail ? this.cookoonCapacityStanding : this.cookoonCapacity
    const chefCapacity = this.chefCapacity
    const maxCapacity =  Math.min(
      cookoonCapacity || 20,
      chefCapacity || 20
    )

    let errorSentence = ""

    if (cookoonCapacity && chefCapacity && peopleCount > cookoonCapacity && peopleCount > chefCapacity) {
      errorSentence = `Le lieu accepte au plus ${cookoonCapacity} convives en format ${ isCocktail ? 'debout' : 'assis'}. Le chef peut cuisiner pour au plus ${chefCapacity} convives.`
    } else if (cookoonCapacity && peopleCount > cookoonCapacity) {
      errorSentence = `Le lieu accepte au plus ${cookoonCapacity} convives en format ${ isCocktail ? 'debout' : 'assis'}.`
    } else if (chefCapacity && peopleCount > chefCapacity) {
      errorSentence = `Le chef peut cuisiner pour au plus ${chefCapacity} convives.`
    }

    this.peopleCountErrorTarget.innerText = errorSentence

    if (errorSentence === "") {
      $(this.peopleCountErrorTarget).hasClass('visible') && $(this.peopleCountErrorTarget).toggleClass(['invisible', 'visible', 'alert', 'alert-danger', 'my-2'])
    } else {
      $(this.peopleCountErrorTarget).hasClass('invisible') && $(this.peopleCountErrorTarget).toggleClass(['invisible', 'visible', 'alert', 'alert-danger', 'my-2'])
    }

    if (peopleCount > maxCapacity) {
      peopleCount = maxCapacity
    }

    $(this.peopleCountTarget).empty()
    const newOptions = Array.from(new Array(maxCapacity - 1), (x, i) => [`${i + 2} convives`, i + 2])
    newOptions.forEach(option => {
      $(this.peopleCountTarget).append(new Option(option[0], option[1], false, peopleCount === option[1]))
    })

    // $(this.peopleCountTarget).trigger('change')
  }
}

import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["form", "startAt", "typeName", "peopleCount", "cookoon", "menu", "cookoonButlerState", "address"]

  connect() {
  }

  fillFormAndSubmit(event) {
    const attributes = event.target.attributes
    this.startAtTarget.value = attributes["data-start-at"].value
    this.typeNameTarget.value = attributes["data-type-name"].value
    this.peopleCountTarget.value = attributes["data-people-count"].value
    this.cookoonTarget.value = attributes["data-cookoon"].value
    this.menuTarget.value = attributes["data-menu"].value
    this.cookoonButlerStateTarget.value = attributes["data-cookoon-butler-state"].value
    this.addressTarget.value = attributes["data-address"].value
    // Rails.fire(this.formTarget, 'submit');
    this.formTarget.submit()
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['supplierModal', 'supplierModalBody', 'menuModal', 'menuModalBody'];
  reservationId = this.data.get('reservationId')

  replaceSupplierModalBodyAndOpenModal(event) {
    const url = `${window.location.origin}/public/reservations/${this.reservationId}/${event.currentTarget.dataset.supplierType.toLowerCase()}s/${event.currentTarget.dataset.supplierId}.json`

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.modalBody) {
          this.supplierModalBodyTarget.innerHTML = data.modalBody
          $(this.supplierModalTarget).modal('show')
        }
      })
  }

  replaceMenuModalBodyAndOpenModal(event) {
    const url = `${window.location.origin}/public/reservations/${this.reservationId}/menus/${event.currentTarget.dataset.menuId}.json`
    console.log(url)
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.modalBody) {
          this.menuModalBodyTarget.innerHTML = data.modalBody
          $(this.menuModalTarget).modal('show')
        }
      })
  }
}

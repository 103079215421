import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "nestedForm", "templateBusinessAddress", "templateCustomerAddress"]
  // static values = { index: String }
  index = this.data.get('index')

  add_association(event) {
    event.preventDefault()
    // var content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), new Date().getTime())
    var content = this.templateTarget.innerHTML.replace(new RegExp(this.index, 'g'), new Date().getTime())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  replaceAssociation(event) {
    const address = document.querySelector(".form-group.reservation_addresses_address input").value
    const additionalInformations = document.querySelector(".form-group.reservation_addresses_additional_informations textarea").value
    const content = (event.currentTarget.checked) ? (this.templateBusinessAddressTarget.innerHTML.replace(new RegExp(this.index, 'g'), new Date().getTime())) : (this.templateCustomerAddressTarget.innerHTML.replace(new RegExp(this.index, 'g'), new Date().getTime()))
    this.nestedFormTarget.innerHTML = content
    document.querySelector(".form-group.reservation_addresses_address input").value = address
    document.querySelector(".form-group.reservation_addresses_additional_informations textarea").value = additionalInformations
    this.nestedFormTarget.querySelectorAll("input.is-invalid").forEach(invalidInput => {
      invalidInput.classList.remove("is-invalid")
    })
  }
}

const updateTypeNamesForDate = (unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date) => {
  if (unavailableDatesForCookoonUrl || unavailableDatesForChefUrl) {
    unavailableTypeNamesForDate(
      unavailableDatesForCookoonUrl,
      unavailableDatesForChefUrl,
      typeNames,
      date
    ).then(availabilities => {
      Object.entries(availabilities).forEach(([typeName, availability]) => {
        if (availability === "unavailable" ) {
          $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', true).prop('disabled', true)
          $(`input[name='reservation[type_name]'][value=${typeName}]`).attr('disabled', true).prop('disabled', true)
          !$(`input[name='reservation[type_name]'][value=${typeName}]`).closest(".card").hasClass('text-secondary') && $(`input[name='reservation[type_name]'][value=${typeName}]`).closest(".card").toggleClass('text-secondary')
        } else {
          $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', false).prop('disabled', false)
          $(`input[name='reservation[type_name]'][value=${typeName}]`).attr('disabled', false).prop('disabled', false)
          $(`input[name='reservation[type_name]'][value=${typeName}]`).closest(".card").hasClass('text-secondary') && $(`input[name='reservation[type_name]'][value=${typeName}]`).closest(".card").toggleClass('text-secondary')
        }
      })
      $("select#reservation_type_name").select2({
        minimumResultsForSearch: -1,
      })
    })
  }
}

const unavailableTypeNamesForDate = (unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date) => {
  const cookoonPromises = []
  const chefPromises = []
  if (unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
    typeNames.forEach(typeName => {
      cookoonPromises.push(isTypeNameUnavailableForSupplierAndDate(unavailableDatesForCookoonUrl, typeName, date))
      chefPromises.push(isTypeNameUnavailableForSupplierAndDate(unavailableDatesForChefUrl, typeName, date))
    })
    return Promise.all(
      [
        Promise.all(cookoonPromises),
        Promise.all(chefPromises)
      ]
    ).then(results => {
      const cookoonAvailabilities = fillSupplierAvailabilities(results[0])
      const chefAvailabilities = fillSupplierAvailabilities(results[1])

      const availabilities = cookoonAvailabilities

      Object.entries(availabilities).forEach(([key, value]) => {
        if (value != chefAvailabilities[key]) {
          availabilities[key] = "unavailable"
        }
      })

      return availabilities
    })
  } else if (unavailableDatesForCookoonUrl && !unavailableDatesForChefUrl) {
    typeNames.forEach(typeName => {
      cookoonPromises.push(isTypeNameUnavailableForSupplierAndDate(unavailableDatesForCookoonUrl, typeName, date))
    })
    return Promise.all(cookoonPromises).then((results) => {
      return fillSupplierAvailabilities(results)
    })
  } else if (!unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
    typeNames.forEach(typeName => {
      chefPromises.push(isTypeNameUnavailableForSupplierAndDate(unavailableDatesForChefUrl, typeName, date))
    })
    return Promise.all(chefPromises).then((results) => {
      return fillSupplierAvailabilities(results)
    })
  }
}

const fillSupplierAvailabilities = (results) => {
  const availabilities = {}
  const SupplierAvailabilities = results.map(element => {
    return Object.fromEntries(element)
  })
  SupplierAvailabilities.forEach(element => {
    availabilities[Object.keys(element)[0]] = Object.values(element)[0]
  })
  return availabilities
}

const isTypeNameUnavailableForSupplierAndDate = (url, typeName, date) => {
  const urlToFetch = `${url}?type_name=${typeName}&start_date_available=${date}&end_date_available=${date}`
  return fetch(urlToFetch)
    .then(response => response.json())
    .then((data) => {
      return [data.datesUnavailable.length].map(element => {
        return element === 0 ? "available" : "unavailable"
      }).map(element => {
        return [typeName, element]
      })
    }).catch(error => {
      // console.log(error)
    })
}

export { updateTypeNamesForDate };
